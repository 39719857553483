import React, { useEffect } from "react";
import Showdown from "showdown";

////////////////////////////////////////////////////////////////////////

export const isWindow = (typeof window !== 'undefined');

const threshold = 30;

var headerHeight = '';

if (isWindow) {
    setTimeout(() => { 
      let header = document.querySelector('header');
      if( header && header.hasOwnProperty('clientHeight') ){
          headerHeight = document.querySelector('header').clientHeight; 
      }        
    }, 1000);
}

////////////////////////////////////////////////////////////////////////

function touchDeviceCheck() {
    if (isWindow) {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    }
}


////////////////////////////////////////////////////////////////////////


export const interaction = (touchDeviceCheck()) ? 'touchstart' : 'click';
export const isiAnchorThreshold = 300;

/**************************/
/*
    start - device
    notes: device breakpoints
*/

export const device = {
    smmobile: `only screen and (max-width: 400px)`,
    lgmobile: `only screen and (max-width: 420px)`,
    mobile: `only screen and (max-width: 767px)`,
    betweenTabletAndDesktop: `only screen and (min-width: 768px) and (max-width: 1199px)`,
    tabletBelow: `only screen and (max-width: 991px)`,
    tablet: `only screen and (min-width: 767px) and (max-width: 991px)`,
    tabletxl: `only screen and (min-width: 767px) and (max-width: 1281px)`,
    desktop: `only screen and (min-width: 992px) and (max-width: 1199px)`,
    desktop_max: `only screen and (min-width: 1199px) and (max-width: 1280px)`,
    desktop_lg: `only screen and (min-width: 1200px)`,
    desktop_xlg: `only screen and (min-width: 1280px)`,
    desktop_xlglm: `only screen and (min-width: 1281px)`,
    desktop_isi: `only screen and (max-width: 1315px)`,
    desktop_xlgmx: `only screen and (max-width: 1280px)`,
    desktop_xlgmxlm: `only screen and (max-width: 1281px)`,
    ie11: `screen and (-ms-high-contrast: active), (-ms-high-contrast: none)`,
}

/* end - device */
/************************/



/**************************/
/*
    start - createMarkup()
    notes: Returns an html object
*/
export function createMarkup(htmlObj) {
    return { __html: htmlObj }
}
/* end - createMarkup() */
/************************/



/**************************/
/*
    start - convertMarkdown()
    notes:  Takes html markup as a string
            Showdown is a Javascript Markdown to HTML converter (https://github.com/showdownjs/showdown)
            Function will return injecting a div element containing the converted markdown to html
*/
export function convertMarkdown(mark) {
    var converter = new Showdown.Converter();

    return (
        <div dangerouslySetInnerHTML={createMarkup(converter.makeHtml(mark))} />
    )
}
/* end - convertMarkdown() */
/************************/


///////////////////////////////////////////////////////////////////////////////////////



export const adjustScrollPosition = (e, section) => {

    headerHeight = document.querySelector('header').clientHeight; 

    const linkTimeout = isiAnchorThreshold;

    let sectionYposition = '';

    const scrollToSection = (posY) => {
        window.scrollTo({
            top: posY - (headerHeight + threshold),
            behavior: 'smooth'
        });
    };

    if (e) {
        if (e.target.parentNode.parentNode.parentNode.id === 'navigation') {
            e.preventDefault();
            setTimeout(() => {
                const _linkedSection = document.getElementById(e.target.parentNode.dataset.link);
                window.location.hash = _linkedSection.id;
                sectionYposition = _linkedSection.offsetTop;
                scrollToSection(sectionYposition);
            }, linkTimeout);
        }
    }
    else if (section) {
        setTimeout(() => {
            
          let posY;
          
          // //To check if zoomed in
          //   let DPR = window.devicePixelRatio; 
          //   
          //   console.log('window.devicePixelRatio: ' + DPR);

          //   if ((DPR > 1 && DPR < 2 ) || (DPR > 2 )) {
          //     //Zoomed in non-retina
          //     posY = document.getElementById(section).offsetTop + (80 * (DPR));

          //     if (DPR > 2) {
          //       //Retina Display
          //       posY = document.getElementById(section).offsetTop + (80 * (DPR/2));
          //     }
          //     console.log('Offset +: ' + 80 * (DPR));
              
          //   } else {
          //     console.log('ELSE')
          //     posY = document.getElementById(section).offsetTop + 10;
          //   }

            posY = document.getElementById(section).offsetTop;

            scrollToSection(posY);
        }, 500);
    }
    else {
        if (isWindow) {
            const hashTrigger = window.location.hash;
            const needSubNav = document.querySelector('header').classList.contains('secondary');
            let _initialSection = '';

            setTimeout(() => {
                if (needSubNav) {
                    if (hashTrigger.length > 0) {
                        if(hashTrigger.indexOf('?') > 0){
                            const hashfinder = hashTrigger.indexOf('?');
                            const newhash = hashTrigger.slice(1, hashfinder);
                            _initialSection = document.getElementById(newhash);
                        }
                        else {
                            _initialSection = document.getElementById(hashTrigger.substring(1));
                        }
                    }
                    else {
                        _initialSection = document.querySelector(`.md-container > section:nth-of-type(1)`);
                    }

                    if (_initialSection) {
                        sectionYposition = _initialSection.offsetTop;
                    }

                    scrollToSection(sectionYposition);
                }
            }, linkTimeout);
        }
    }
};



////////////////////////////////////////////////////////////////////////////////



export const AnchorLinking = (props) => {
    useEffect(() => {
        setTimeout(() => {
            const _anchorLinks = document.querySelectorAll('.anchor-link');
            let section = '';
            let posY = '';
            let anchorThreshold = '';
            let customThreshold = props.threshold;

            (customThreshold > -1) ? anchorThreshold = customThreshold : anchorThreshold = threshold;

            _anchorLinks.forEach((el) => {
                el.addEventListener(interaction, (e) => {
                    e.preventDefault();
                    section = el.hash.substring(1);
                    posY = document.getElementById(section).offsetTop;
                    window.scrollTo({
                        top: posY - (headerHeight + anchorThreshold),
                        behavior: 'smooth'
                    });
                });
            });
        }, 400);
    });

    return null;
};

export const preventFocusOutsideModals = (selector = null) => {
    if (!selector) return;
    // add all the elements inside modal which you want to make focusable
    const focusableElements =
        ':not(.modal-trigger):is(button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]))';
    const modal = document.getElementById(selector); // select the modal by it's id
    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    const focusableContent = modal.querySelectorAll(focusableElements);
    const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal
    let isFakeButton = true;
    const fakeButton = document.createElement("button");
    fakeButton.classList.add("fake-button");
    fakeButton.style.clipPath = "polygon(0% 0%, 0% 0%, 0% 0%)";
    fakeButton.style.position = "absolute";
    modal.prepend(fakeButton);
    fakeButton.focus()

    modal.addEventListener("keydown", function (e) {
        let isTabPressed = e.key === "Tab" || e.keyCode === 9;

        if (!isTabPressed) {
        return;
        }

        if (isFakeButton) {
        modal.removeChild(fakeButton);
        isFakeButton = false;
        }

        if (e.shiftKey) {
        // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus(); // add focus for the last focusable element
            e.preventDefault();
        }
        } else {
        // if tab key is pressed
        if (document.activeElement === lastFocusableElement) {
            // if focused has reached to last focusable element then focus first focusable element after pressing tab
            firstFocusableElement.focus(); // add focus for the first focusable element
            e.preventDefault();
        }
        }
    });
};
